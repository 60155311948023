import React, { useState } from "react"

// component
import Img from "gatsby-image"

export default function ImgSelector({ cardImg, galleryImages, title }) {
  const gallery = [cardImg, ...galleryImages]
  const [current, setCurrent] = useState(cardImg)

  return (
    <div className="self-center xl:self-start max-w-full">
      <div className="flex flex-wrap-reverse items-end self-start ">
        {gallery.map(img => {
          return (
            <div
              key={img.childImageSharp.fluid.src}
              className={`w-12 h-12 mr-2 mb-4 flex justify-center rounded items-center duration-200 overflow-hidden shadow-lg cursor-pointer ${
                current === img && "border-4 border-green-500"
              }`}
              aria-label="click to change image"
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setCurrent(img)
              }}
              onClick={() => {
                setCurrent(img)
              }}
            >
              <Img
                fluid={img.childImageSharp.fluid}
                alt={`image of ${title}`}
                className="w-32 h-32"
              />
            </div>
          )
        })}
      </div>
      <div
        className="h-full overflow-hidden self-start flex items-center rounded-lg shadow-lg"
        style={{ height: "min-content" }}
      >
        <div className="w-128">
          <Img
            fluid={current.childImageSharp.fluid}
            alt={`image of ${title}`}
          />
        </div>
      </div>
    </div>
  )
}
