import React from "react"
import Layout from "../components/layout"
import "../style/styles.css"

// components
import ImgSelector from "../components/imgSelector"

const project = ({ pageContext }) => {
  const { title, location, description, cardImg, galleryImages } = pageContext

  return (
    <Layout>
      <div className="fixed bg-gray-900 w-screen h-screen -z-10" />
      <div className="container mx-auto px-5 pt-24 xl:pt-32 pb-12 flex flex-col flex-wrap text-white">
        <div className="flex xl:flex-row flex-col lg:flex-wrap-reverse xl:flex-no-wrap items-start max-w-full">
          <div className="xl:hidden self-center w-128 max-w-full">
            <p className="m-0 uppercase text-gray-500 tracking-widest text-sm font-bold">
              {location}
            </p>
            <h1 className="mt-2 mb-4">{title}</h1>
          </div>
          <ImgSelector
            cardImg={cardImg}
            galleryImages={galleryImages}
            title={title}
          />
          <div className="flex flex-col xl:ml-10 xl:self-start self-center max-w-full">
            <div className="lg:hidden md:hidden sm:hidden xs:hidden">
              <p className="m-0 uppercase text-gray-500 tracking-widest text-sm font-bold">
                {location}
              </p>
              <h1 className="mt-2 mb-4">{title}</h1>
            </div>
            <p className="mt-4 m-0 text-gray-400 w-128 max-w-full">
              {description}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default project
